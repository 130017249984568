/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
#root {
  width: 80%;
}

body {
  position: relative;
  color: white;
  height: 100vh;
  background: linear-gradient(
    200.96deg,
    /* #b2ffff -29.09%,
    #3abeff 51.77%,
    #3c59ff 129.35% */ #dc1c43 -29.09%,
    #f07470 51.77%,
    #f6bdc0 129.35%
  );
  font-family: sans-serif;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  background-color: white;
  padding: 10px 40px;
  border-radius: 10px;
  border: none;
  appearance: none;
  font-size: 1.3rem;
  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  transition: transform ease-in 0.1s;
  cursor: pointer;
}

button:hover {
  transform: scale(1.05);
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  height: fit-content;
  width: fit-content;
  margin: 10px;
}

a:hover {
  opacity: 1;
  text-decoration: none;
}

.Hello {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.headline_icon {
  display: block;
  margin: 0 auto;
}

/* Utility */
.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.btnGroup {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnGroup__btn {
  margin: 10px;
}

.btn button {
  display: block;
}

.btn--center button {
  display: block;
  margin: 0 auto;
  /* color: #3c59ff; */
}

.page-container {
  /* max-width: 80%;
  width: 80%; */
}

.dropzone__drop_area {
  height: 100px;
  /* width: 100%; */
  /* width: 80%; */
  padding: 10px;
  background-color: #d3d3d3;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone ul {
  padding-left: 0;
}

.dropzone li {
  word-break: break-word;
}

/* File Group */
.outFileGroup {
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
}

.outFileGroup__item {
  display: block;
}

.outFileGroup__item a {
  color: white;
}
